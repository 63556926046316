import React, { useContext, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import ModalContainer from "../../Utils/Components/ModalContainer";
import TranslationContent from "../../../Translations/TranslationContent";
import FormTextField from "../../Utils/Components/FormTextField";
import LoadingSpinner from "../../Loading/LoadingSpinner";
import ConfirmDialog from "../../Utils/Components/ConfirmDialog";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { Tokens } from "../../../Services/tokens";
import partnersService from "../../../Services/partners";

const PartnerEditForm = ({
  partner,
  fetchPartners,
  deletePartner,
  closeForm,
}) => {
  const [editedPartner, setEditedPartner] = useState(partner);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { addAlert } = useContext(SnackBarContext);

  const isNewPartner = !partner.id;

  const handleClose = () => {
    setOpen(false);
    closeForm && closeForm();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const tokens = await Tokens();

      if (isNewPartner) {
        await partnersService.createPartner(
          {
            name: editedPartner.name,
            code: editedPartner.code,
            country: "Finland",
          },
          tokens.accessToken,
          tokens.idToken
        );
      } else {
        await partnersService.updatePartner(
          {
            id: editedPartner.id,
            name: editedPartner.name,
            country: "Finland",
            code: editedPartner.code,
          },
          tokens.accessToken,
          tokens.idToken
        );
      }

      fetchPartners();
      handleClose();
      addAlert({ message: "success", type: "success" });
    } catch (error) {
      addAlert({ message: "error", type: "error" });
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeletePartner = async () => {
    setLoading(true);
    setShowDeleteDialog(false);

    try {
      await deletePartner(partner.id);
      fetchPartners();
      handleClose();
    } catch (error) {
      console.error("Error deleting partner:", error);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <ModalContainer open={open} handleClose={handleClose}>
        <LoadingSpinner />
      </ModalContainer>
    );
  } else if (showDeleteDialog) {
    return (
      <ConfirmDialog
        open={showDeleteDialog}
        title={<TranslationContent contentID={"delete_confirmation_title"} />}
        text={<TranslationContent contentID={"delete_confirmation_text"} />}
        handleClose={() => setShowDeleteDialog(false)}
        confirmClick={handleDeletePartner}
      />
    );
  } else {
    return (
      <ModalContainer open={open} handleClose={handleClose}>
        <Typography variant="h5">
          <TranslationContent
            contentID={isNewPartner ? "addPartner" : "editPartner"}
          />
        </Typography>
        <form onSubmit={handleSave}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            {/* Partner Name */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                <TranslationContent contentID={"partnerName"} />
              </Typography>
              <FormTextField
                size="small"
                value={editedPartner.name}
                onChange={(event) =>
                  setEditedPartner({
                    ...editedPartner,
                    name: event.target.value,
                  })
                }
                fullWidth
                required
              />
            </Grid>

            {/* Partner Code */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                <TranslationContent contentID={"partnerCode"} />
              </Typography>
              <FormTextField
                size="small"
                value={editedPartner.code}
                onChange={(event) =>
                  setEditedPartner({
                    ...editedPartner,
                    code: event.target.value,
                  })
                }
                fullWidth
                required
              />
            </Grid>

            {/* Action Buttons */}
            <Grid
              container
              sx={{
                borderTop: "1px solid #ccc",
                paddingTop: "16px",
                marginTop: "16px",
              }}
            >
              <Grid
                item
                xs={isNewPartner ? 12 : 6}
                sx={{ textAlign: "center" }}
              >
                <Button type="submit" variant="contained" color="primary">
                  <TranslationContent
                    contentID={isNewPartner ? "add" : "save"}
                  />
                </Button>
              </Grid>

              {/* Hide Delete Button When Adding a New Partner */}
              {!isNewPartner && (
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    <TranslationContent contentID={"delete"} />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </ModalContainer>
    );
  }
};

export default PartnerEditForm;
