import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Avatar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ModalContainer from "../../Utils/Components/ModalContainer";
import TranslationContent from "../../../Translations/TranslationContent";
import FormTextField from "../../Utils/Components/FormTextField";
import { TextField } from "@mui/material";
import CalendarPicker from "../../Utils/Components/CalendarPicker";
import LoadingSpinner from "../../Loading/LoadingSpinner";
import ConfirmDialog from "../../Utils/Components/ConfirmDialog";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { Tokens } from "../../../Services/tokens";
import registrationsService from "../../../Services/registrations";
import pdfService from "../../../Services/pdf";
import emailService from "../../../Services/email";
import RuukkiLogo from "../../../images/ruukki-header.png";
import WarrantyLogo from "../../Utils/GlobalComponents/WarrantyLogo";

const editableValues = {
  customer: true,
  phone_number: true,
  email: true,
  address: true,
  country: true,
};
const roofDisplayValues = {
  asennus_str: false,
  description: false,
  material: false,
  coating_type: false,
  coating_colour: false,
  quality_class: false,
  quality_class_id: false,
  technical_warranty_length: false,
  aesthetic_warranty_length: false,
  install_date: true,
  delivery_date: false,
  mechanic_comment: true,
};

const productDisplayValues = {
  type: true,
  description: true,
  technical_warranty_length: true,
  aesthetic_warranty_length: true,
  material: true,
  delivery_date: true,
};

const RegistrationEditFrom = ({
  registration,
  fetchRegistrations,
  deleteRegistration,
}) => {
  const [editedRegistration, setEditedRegistration] = useState(registration);

  const [installerInput, setInstallerInput] = useState(
    !editedRegistration?.partner || editedRegistration?.partner?.code === ""
      ? editedRegistration?.installerName
      : editedRegistration?.partner?.code
  );

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { addAlert } = useContext(SnackBarContext);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const tokens = await Tokens();

      const updatedData = {
        ...editedRegistration,
        installerInput: installerInput,
      };

      await registrationsService.updateRegistration(
        updatedData,
        tokens.accessToken,
        tokens.idToken
      );

      fetchRegistrations();
      setOpen(false);
      addAlert({ message: "success", type: "success" });
    } catch (error) {
      addAlert({ message: "error", type: "error" });
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeleteRegistration = async () => {
    console.log("deleteRegistration", registration);
    setLoading(true);
    setShowDeleteDialog(false);

    deleteRegistration(registration.id);

    fetchRegistrations();
    setOpen(false);
    setLoading(false);
  };

  const openPDF = async (event) => {
    const pdfId = registration.pdf.key;
    try {
      const tokens = await Tokens();
      const response = await pdfService.fetchPDF(
        {
          pdfId,
          // country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      const byteCharacters = atob(response.data);
      const byteNumbers = byteCharacters
        .split("")
        .map((char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    } catch (e) {
      console.error("Error in openPDF: ", e);
    }
  };

  const sendPDFToCustomer = async () => {
    setLoading(true);
    try {
      const tokens = await Tokens();
      await emailService.resendEmail(
        {
          email: registration.email,
          pdf: registration.pdf.key,
          language: registration.language,
        },
        tokens.accessToken,
        tokens.idToken
      );
      addAlert({ message: "success", type: "success" });
    } catch (error) {
      addAlert({ message: "error", type: "error" });
    }
    setLoading(false);
  };

  const modifyObjectInArray = ({ arrayName, index, key }, newValue) => {
    let array = editedRegistration[arrayName];
    array[index][key] = newValue;
    setEditedRegistration({
      ...editedRegistration,
      [arrayName]: array,
    });
  };

  const partnerIsSameAsInput =
    editedRegistration?.partner?.code === installerInput;

  if (loading) {
    return (
      <ModalContainer open={open} handleClose={handleClose} onEdit={handleOpen}>
        <LoadingSpinner />
      </ModalContainer>
    );
  } else if (showDeleteDialog) {
    return (
      <ConfirmDialog
        open={showDeleteDialog}
        title={<TranslationContent contentID={"delete_confirmation_title"} />}
        text={<TranslationContent contentID={"delete_confirmation_text"} />}
        handleClose={() => setShowDeleteDialog(false)}
        confirmClick={handleDeleteRegistration}
      />
    );
  } else {
    return (
      <ModalContainer open={open} handleClose={handleClose} onEdit={handleOpen}>
        <Typography variant="h5">
          <TranslationContent contentID={"editRegistration"} />
        </Typography>
        <form onSubmit={handleSave}>
          <Grid
            container
            spacing={1}
            sx={{
              padding: "8px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                <TranslationContent contentID={"customerInfromation"} />
              </Typography>
            </Grid>
            {Object.keys(editableValues).map((key) => (
              <Grid key={key} container sx={{ paddingLeft: "8px" }}>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    <TranslationContent contentID={key} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} sx={{ marginBottom: "12px" }}>
                  <FormTextField
                    size="small"
                    value={editedRegistration[key]}
                    onChange={(event) =>
                      setEditedRegistration({
                        ...editedRegistration,
                        [key]: event.target.value,
                      })
                    }
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                <TranslationContent contentID={"additionalInfromation"} />
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                <TranslationContent contentID={"deliveryDate"} />
              </Typography>
            </Grid>{" "}
            <Grid item xs={6} md={9}>
              <Typography variant="subtitle1">
                {editedRegistration.roofs[0].delivery_date}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                <TranslationContent contentID={"status"} />
              </Typography>
            </Grid>{" "}
            <Grid item xs={6} md={9}>
              <Typography variant="subtitle1">
                {editedRegistration.status}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                fontWeight="bold"
                style={{ display: "flex", alignItems: "center" }}
              >
                {editedRegistration.marketing_allowed ? (
                  <CheckIcon style={{ fill: "green" }} />
                ) : (
                  <ClearIcon style={{ fill: "red" }} />
                )}
                <TranslationContent
                  contentID={
                    editedRegistration.marketing_allowed
                      ? "marketingAllowed"
                      : "marketingNotAllowed"
                  }
                />
              </Typography>
            </Grid>
            {!editedRegistration.originalWarranty ||
            registration.forceOriginalWarranty ? (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        editedRegistration.forceOriginalWarranty || false
                      }
                      color="primary"
                      onChange={(event) =>
                        setEditedRegistration({
                          ...editedRegistration,
                          forceOriginalWarranty: event.target.checked,
                        })
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" fontWeight="bold">
                      <TranslationContent contentID={"forceOriginalWarranty"} />
                    </Typography>
                  }
                />
              </Grid>
            ) : null}
            {/* Installer Info */}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ marginTop: "16px", borderBottom: "1px solid red" }}
              >
                <TranslationContent contentID={"installerInfo"} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                <TranslationContent contentID={"installer"} />
              </Typography>
              <TextField
                size="small"
                id="installer-name"
                defaultValue={installerInput}
                onChange={(event) => setInstallerInput(event.target.value)}
                fullWidth
              />
              {editedRegistration?.partner?.name && partnerIsSameAsInput && (
                <Typography
                  variant="subtitle2"
                  sx={{ color: "gray", marginTop: "4px" }}
                >
                  {editedRegistration.partner.name}
                </Typography>
              )}
            </Grid>
            {/* PARTNER STAMP/MEDAL */}
            {editedRegistration?.partner?.code && partnerIsSameAsInput && (
              <Grid item xs={6} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                >
                  {/* Silver Stamp-Style Avatar */}
                  <Avatar
                    sx={{
                      bgcolor: "transparent",
                      border: "4px solid silver",
                      width: 120,
                      height: 120,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 1,
                      background: "linear-gradient(145deg, #d9d9d9, #f5f5f5)",
                      boxShadow:
                        "0px 0px 8px rgba(0, 0, 0, 0.3), inset 0px 0px 6px rgba(255, 255, 255, 0.6)",
                    }}
                  >
                    {/* Ruukki Logo */}
                    <Box
                      component="img"
                      src={RuukkiLogo}
                      alt="Ruukki"
                      sx={{ width: "60%", marginBottom: 0.5 }}
                    />

                    {/* Partner Label */}
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        fontFamily:
                          "Impact, Haettenschweiler, Arial Narrow, sans-serif",
                        color: "black",
                        fontSize: "18px",
                      }}
                    >
                      <TranslationContent contentID={"partner"} />
                    </Typography>

                    {/* Partner Code */}
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{
                        fontFamily: "Arial Black, Gadget, sans-serif",
                        color: "black",
                        fontSize: "18px",
                      }}
                    >
                      {editedRegistration?.partner?.code}
                    </Typography>
                  </Avatar>
                </Box>
              </Grid>
            )}
            {editedRegistration?.originalWarranty && (
              <Grid item xs={6} md={3}>
                <WarrantyLogo value={"R50"} />
              </Grid>
            )}
            {/* Roofs */}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  marginTop: "16px",
                  borderBottom: "1px solid red",
                }}
              >
                <TranslationContent contentID={"roofs"} />
              </Typography>
            </Grid>
            {editedRegistration.roofs.map((roof, index) => (
              <Grid
                key={roof.id + index}
                container
                spacing={1}
                sx={{
                  padding: "8px",
                  // border: "1px solid red",
                }}
              >
                {Object.keys(roofDisplayValues).map((key) => (
                  <Grid key={roof.id + key} item xs={12} md={6}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        <TranslationContent contentID={key} />
                      </Typography>
                    </Grid>
                    {roofDisplayValues[key] ? (
                      <Grid item xs={12}>
                        {key === "install_date" ? (
                          <CalendarPicker
                            date={editedRegistration.roofs[index][key]}
                            setDate={modifyObjectInArray}
                            props={{
                              arrayName: "roofs",
                              index: index,
                              key: key,
                            }}
                          />
                        ) : (
                          <FormTextField
                            size="small"
                            value={editedRegistration.roofs[index][key]}
                            onChange={(event) =>
                              modifyObjectInArray(
                                { arrayName: "roofs", index, key },
                                event.target.value
                              )
                            }
                            fullWidth
                            required={key === "mechanic_comment" ? false : true}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">{roof[key]}</Typography>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            ))}
            {/* Products */}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  marginTop: "16px",
                  // marginBottom: "16px",
                  borderBottom: "1px solid red",
                }}
              >
                <TranslationContent contentID={"products"} />
              </Typography>
            </Grid>
            {editedRegistration.products
              .filter((p) => p.comments === "")
              .map((product) => (
                <Grid
                  key={product.name}
                  container
                  spacing={1}
                  sx={{
                    padding: "8px",
                    // marginBottom: "16px",
                    // borderBottom: "1px solid red",
                  }}
                >
                  {Object.keys(productDisplayValues).map((key) => (
                    <Grid key={key} item xs={12} md={6}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          <TranslationContent contentID={key} />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          <TranslationContent contentID={product[key]} />
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ))}
          </Grid>
          <Grid
            container
            sx={{ borderTop: "1px solid red", paddingTop: "16px" }}
          >
            <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
              <Button type="submit">
                <TranslationContent contentID={"save"} />
              </Button>
            </Grid>

            <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
              <Button onClick={() => openPDF()}>
                <TranslationContent contentID={"openPdf"} />
              </Button>
            </Grid>

            <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
              <Button onClick={() => sendPDFToCustomer()}>
                <TranslationContent contentID={"emailToCustomer"} />
              </Button>
            </Grid>

            <Grid item xs={6} md={3} sx={{ textAlign: "center" }}>
              <Button onClick={() => setShowDeleteDialog(true)}>
                <TranslationContent contentID={"delete"} />
              </Button>
            </Grid>
          </Grid>
        </form>
      </ModalContainer>
    );
  }
};

export default RegistrationEditFrom;
