import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CalendarPicker from "../Utils/Components/CalendarPicker";
import dayjs from "dayjs";
import TranslationContent from "../../Translations/TranslationContent";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import WarrantyLogo from "../Utils/GlobalComponents/WarrantyLogo";
import FormTextField from "../Utils/Components/FormTextField";
import { translations } from "../../Translations/translations";
import { processResponse } from "./InfoHandler";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { LanguageContext } from "../../Translations/LanguageProvider";
import registrationsService from "../../Services/registrations";
import tokenService from "../../Services/tokens";

const productTypes = [
  "flashing",
  "rws",
  "rsp",
  "lead_through",
  "underlay",
  "screw",
];

const containerStyle = {
  wordWrap: "break-word", // Allows long words to break and wrap onto the next line
  overflowWrap: "break-word", // Standard syntax for word-wrap
  hyphens: "auto", // Automatically add hyphens when breaking words
};

const RegistrationPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [customer, setCustomer] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [additionalIdentifier, setAdditionalIdentifier] = useState(null);
  const [roof, setRoof] = useState(null);
  const [products, setProducts] = useState([]);
  const [token, setToken] = useState("");

  const [installerInput, setInstallerInput] = useState("");

  const [mechanicComment, setMechanicComment] = useState("");
  const [installDate, setInstallDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [postalOffice, setPostalOffice] = useState("");
  const [marketingAllowed, setMarketingAllowed] = useState(false);

  const language = useContext(LanguageContext);
  const { addAlert } = useContext(SnackBarContext);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    setToken(token);

    try {
      fetchData(token);
    } catch (error) {
      setError(true);
      setErrorMessage("errorDecodingToken");
      console.error("Error decoding token:", error);
    }
  }, []);

  const fetchData = async (token) => {
    try {
      const response = await tokenService.verifyToken({
        token,
      });

      const result = processResponse(response, productTypes, translations);

      setCustomer(response?.data?.customer);
      setOrderNumber(response?.data?.orderNumber);
      setAdditionalIdentifier(response?.data?.additionalIdentifier);
      setProducts(result.sortedProducts);
      setRoof(result.groupedRoofs[0]);
      setLoading(false);
    } catch (error) {
      setError(true);
      setErrorMessage("errorFetchingData");
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const validDate = dayjs(installDate, "DD-MM-YYYY").isValid();

    if (!validDate) {
      console.log("NOT VALID DATE!!!");
      addAlert({ message: "errorInvalidDate", type: "error" });
      setLoading(false);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validEmail = emailRegex.test(email);

    if (!validEmail) {
      console.log("NOT VALID EMAIL!!!");
      addAlert({ message: "errorInvalidEmail", type: "error" });
      setLoading(false);
      return;
    }

    const payload = {
      token,
      customer: customer,
      email: email,
      phone_number: phoneNumber,
      vbeln: orderNumber,
      knumv: additionalIdentifier,
      installerInput: installerInput,
      roofs: [
        {
          ...roof,
          mechanic_comment: mechanicComment,
          install_date: installDate,
        },
      ],
      products: products,
      status: "B",
      address: `${address?.trim()}, ${zipCode?.trim()}, ${postalOffice?.trim()}`,
      country: "Finland", // TODO: this needs to change based on information from SAP (no info yet), other options is to add dropdown selector
      language: language.lang,
      created_at: Date.now(),
      marketing_allowed: marketingAllowed,
    };

    try {
      await registrationsService.createRegistration({
        ...payload,
      });

      navigate("/registration/completed");
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage("Something went wrong");
    }

    setLoading(false);
  };

  const navigateUserToCorrectPage = () => {
    navigate(`/`);
  };

  if (loading) {
    return <Loading />;
  } else if (error) {
    return (
      <MainLayout>
        <Error section={errorMessage} retryClick={navigateUserToCorrectPage} />
      </MainLayout>
    );
  } else {
    return (
      <MainLayout>
        <Grid container spacing={1} sx={{ marginTop: "24px" }}>
          <Grid container spacing={1} sx={{ padding: "0 16px" }}>
            <Grid item xs={12}>
              <Typography variant="h6">
                <TranslationContent contentID="registrationInputHeader" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                margin: "auto",
                textAlign: "left",
                marginTop: "24px",
                padding: "16px",
              }}
            >
              <form onSubmit={handleSubmit}>
                {/* ORDER INFORMATION */}
                <Grid container spacing={2} sx={{ padding: "8px" }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold">
                      <TranslationContent contentID="orderInformation" />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      <TranslationContent contentID="orderNumber" />
                    </Typography>
                    <Typography variant="subtitle2">{orderNumber}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      <TranslationContent contentID="additionalIdentifier" />
                    </Typography>
                    <Typography variant="subtitle2">
                      {additionalIdentifier}
                    </Typography>
                  </Grid>
                  {/* ROOF PROFILE INFORMATION */}
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold">
                      <TranslationContent contentID="roofProfileInformation" />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <WarrantyLogo value={roof.quality_class_id} />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        padding: "8px",
                      }}
                    >
                      <Grid item xs={4} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="product" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8} md={9}>
                        <Typography variant="subtitle2">
                          {roof.description}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="coating" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8} md={9}>
                        <Typography variant="subtitle2">
                          {roof.coating_type}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="color" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8} md={9}>
                        <Typography variant="subtitle2">
                          {roof.coating_colour}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="deliveryDate" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8} md={9}>
                        <Typography variant="subtitle2">
                          {roof.delivery_date}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* WARRANTY INFORMATION */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        marginTop: "24px",
                        paddingBottom: "24px",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          <TranslationContent contentID="warrantyInformation" />
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="product" />
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="technicalWarranty" />
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="aestheticWarranty" />
                        </Typography>
                      </Grid>

                      <React.Fragment key={`${roof}`}>
                        <Grid item xs={4} style={containerStyle}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            <TranslationContent contentID={"profile"} />
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          {roof?.technical_warranty_length}
                        </Grid>
                        <Grid item xs={4}>
                          {roof?.aesthetic_warranty_length}
                        </Grid>
                      </React.Fragment>

                      {productTypes.map((type, index) => {
                        const product = products.find(
                          (product) => product.type === type
                        );

                        if (product)
                          return (
                            <React.Fragment key={`${type}-${index}`}>
                              <Grid item xs={4} style={containerStyle}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="bold"
                                >
                                  <TranslationContent contentID={type} />
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                {product?.technical_warranty_length}
                              </Grid>
                              <Grid item xs={4}>
                                {product?.aesthetic_warranty_length}
                              </Grid>
                            </React.Fragment>
                          );
                      })}
                    </Grid>
                    {/* INSTALLATION INFORMATION */}
                    <Grid container spacing={1} sx={{ marginTop: "24px" }}>
                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          <TranslationContent contentID="installationInformation" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="mechanic" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={installerInput}
                          onChange={(event) =>
                            setInstallerInput(event.target.value)
                          }
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="installDate" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <CalendarPicker
                          date={installDate}
                          setDate={setInstallDate}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="comment" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={mechanicComment}
                          onChange={(event) =>
                            setMechanicComment(event.target.value)
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    {/* CUSTOMER INFORMATION */}
                    <Grid container spacing={1} sx={{ marginTop: "24px" }}>
                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          <TranslationContent contentID="customerInformation" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="customerOrCondominium" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={customer}
                          onChange={(event) => setCustomer(event.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="phoneNumber" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={phoneNumber}
                          onChange={(event) =>
                            setPhoneNumber(event.target.value)
                          }
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="email" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="address" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={address}
                          onChange={(event) => setAddress(event.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="zipCode" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={zipCode}
                          onChange={(event) => setZipCode(event.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          <TranslationContent contentID="postalOffice" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormTextField
                          size="small"
                          value={postalOffice}
                          onChange={(event) =>
                            setPostalOffice(event.target.value)
                          }
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "16px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={marketingAllowed}
                            onChange={(event) =>
                              setMarketingAllowed(event.target.checked)
                            }
                            value="marketingAllowed"
                          />
                        }
                        label={
                          <Typography variant="subtitle2" fontWeight="bold">
                            <TranslationContent contentID="allowMarketing" />
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ textAlign: "center", marginTop: "24px" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ width: "225px" }}
                    >
                      <TranslationContent contentID="register" />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </MainLayout>
    );
  }
};

export default RegistrationPage;
