import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_API_KEY;

const fetchWarranties = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/warrantyDetails?country=${data.country}&isOriginal=${data.isOriginal}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const fetchDefaultWarranties = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/warrantyDefaults?country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const updateWarranties = async (data, accessToken, idToken) => {
  const response = await axios.put(
    `${apiBaseURL}/warrantyDetails?country=${data.country}`,
    {
      isDraft: data.isDraft,
      isOriginal: data.isOriginal,
      terms: data.terms,
      defaults: data.defaults,
    },
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

export default {
  fetchWarranties,
  fetchDefaultWarranties,
  updateWarranties,
};
