import React from "react";
import { useLocation } from "react-router-dom";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SourceIcon from "@mui/icons-material/Source";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import Handshake from "@mui/icons-material/Handshake";
import HandshakeOutlined from "@mui/icons-material/HandshakeOutlined";

const MenuIconHandler = ({ navigationItem }) => {
  const location = useLocation();

  if (navigationItem.section === "newRegistration") {
    if (location.pathname === navigationItem.path) {
      return <AddCircleIcon color="primary" />;
    } else {
      return <AddCircleOutlineIcon color="primary" />;
    }
  }

  if (navigationItem.section === "registrations") {
    if (location.pathname === navigationItem.path) {
      return <SourceIcon color="primary" />;
    } else {
      return <SourceOutlinedIcon color="primary" />;
    }
  }

  if (navigationItem.section === "warrantyDetails") {
    if (location.pathname === navigationItem.path) {
      return <EditLocationAltIcon color="primary" />;
    } else {
      return <EditLocationOutlinedIcon color="primary" />;
    }
  }

  if (navigationItem.section === "archive") {
    if (location.pathname === navigationItem.path) {
      return <InventoryIcon color="primary" />;
    } else {
      return <Inventory2OutlinedIcon color="primary" />;
    }
  }

  if (navigationItem.section === "partners") {
    if (location.pathname === navigationItem.path) {
      return <Handshake color="primary" />;
    } else {
      return <HandshakeOutlined color="primary" />;
    }
  }
};

export default MenuIconHandler;
