import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
} from "@mui/material";
import TranslationContent from "../../Translations/TranslationContent";
import SearchBar from "../Utils/Components/SearchBar";
import PartnerEditForm from "./Components/PartnerEditForm";
import LoadingSpinner from "../Loading/LoadingSpinner";
import MainLayout from "../../Layouts/MainLayout";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import { AuthContext } from "../../Context/AuthProvider";
import { Tokens } from "../../Services/tokens";
import partnersService from "../../Services/partners";
import { useViewport } from "../../Context/ViewportProvider";

const pageSize = 20;

const Partners = () => {
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);

  const handleOpenEditForm = (partner) => {
    setSelectedPartner(partner);
    setOpenEditForm(true);
  };

  const handleCloseEditForm = () => {
    setOpenEditForm(false);
    setSelectedPartner(null);
  };

  const { addAlert } = useContext(SnackBarContext);
  const auth = useContext(AuthContext);
  const { width } = useViewport();

  const fetchPartnersFromAPI = async (currentPage) => {
    try {
      const tokens = await Tokens();
      const response = await partnersService.fetchPartners(
        {
          currentPage,
          pageSize,
          country: auth.country,
        },
        tokens.accessToken,
        tokens.idToken
      );

      const { partners: newPartners, total } = response.data;
      setPartners(newPartners);
      setTotalPages(Math.ceil(total / pageSize));
    } catch (error) {
      console.error("Failed to fetch partners:", error);
      addAlert({ message: "errorFetchingData", type: "error" });
    }
  };

  const fetchPartners = async () => {
    setLoading(true);
    await fetchPartnersFromAPI(page);
    setLoading(false);
  };

  const deletePartner = async (id) => {
    setLoading(true);
    try {
      const tokens = await Tokens();
      await partnersService.deletePartner(
        {
          id,
          country: "Finland",
        },
        tokens.accessToken,
        tokens.idToken
      );

      fetchPartners();
      addAlert({ message: "deleteSuccess", type: "success" });
    } catch (error) {
      console.log(error);
      addAlert({ message: "errorDeleteFailed", type: "error" });
    }

    setLoading(false);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const searchPartners = async () => {
    setLoading(true);
    const tokens = await Tokens();
    const response = await partnersService.searchPartners(
      {
        searchQuery,
        country: auth.country,
      },
      tokens.accessToken,
      tokens.idToken
    );

    const { partners: newPartners } = response.data;
    setSearchResults(newPartners);
    setLoading(false);
  };

  useEffect(() => {
    if (!searchQuery) {
      setPage(1);
      setSearchResults([]);
      return;
    }

    const handler = setTimeout(() => {
      searchPartners();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    fetchPartners();
  }, [page]);

  return (
    <MainLayout>
      <Typography variant="h5" sx={{ marginBottom: "16px" }}>
        <TranslationContent contentID="partners" />
      </Typography>
      <Paper
        sx={{
          padding: "32px",
          marginTop: "32px",
          "@media (max-width:900px)": {
            padding: "16px",
          },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenEditForm({ id: null, name: "", code: "" })}
          sx={{ marginBottom: "16px" }}
        >
          <TranslationContent contentID="addPartner" />
        </Button>

        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-label="partners table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TranslationContent contentID="partnerName" />
                    </TableCell>
                    {width > 600 && (
                      <TableCell>
                        <TranslationContent contentID="partnerCode" />
                      </TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchResults.length > 0 ? searchResults : partners).map(
                    (partner) => (
                      <TableRow key={partner.id}>
                        <TableCell>{partner.name}</TableCell>
                        {width > 600 && <TableCell>{partner.code}</TableCell>}
                        <TableCell align="right">
                          <Button
                            color="primary"
                            onClick={() => handleOpenEditForm(partner)}
                            sx={{ color: "red", fontWeight: "bold" }}
                          >
                            <TranslationContent contentID="edit" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {searchResults.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handlePreviousPage} disabled={page <= 1}>
                  <TranslationContent contentID={"previous"} />
                </Button>
                <Typography
                  variant="body1"
                  sx={{ margin: "0 20px", lineHeight: "36px" }}
                >
                  {page} / {totalPages}
                </Typography>
                <Button onClick={handleNextPage} disabled={page >= totalPages}>
                  <TranslationContent contentID={"next"} />
                </Button>
              </div>
            )}
          </>
        )}
      </Paper>

      {openEditForm && (
        <PartnerEditForm
          open={openEditForm}
          partner={selectedPartner}
          fetchPartners={fetchPartners}
          deletePartner={deletePartner}
          closeForm={handleCloseEditForm}
        />
      )}
    </MainLayout>
  );
};

export default Partners;
