import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_API_KEY;

const fetchPartners = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/partners?page=${data.currentPage}&pageSize=${data.pageSize}&country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const searchPartners = async (data, accessToken, idToken) => {
  const response = await axios.get(
    `${apiBaseURL}/partners?search=${data.searchQuery}&country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

// Create a new special partner
const createPartner = async (data, accessToken, idToken) => {
  const response = await axios.post(
    `${apiBaseURL}/partners?country=${data.country}`,
    {
      ...data,
    },
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const updatePartner = async (data, accessToken, idToken) => {
  const response = await axios.put(
    `${apiBaseURL}/partners?country=${data.country}`,
    {
      ...data,
    },
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const deletePartner = async (data, accessToken, idToken) => {
  const response = await axios.delete(
    `${apiBaseURL}/partners?id=${data.id}&country=${data.country}`,
    {
      headers: {
        "x-api-key": apiKey,
        Authorization: "Bearer " + accessToken,
        "x-id-token": "Bearer " + idToken,
      },
    }
  );

  return response;
};

const partnerServices = {
  fetchPartners,
  searchPartners,
  createPartner,
  updatePartner,
  deletePartner,
};
export default partnerServices;
