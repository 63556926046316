import "./App.css";
import { AuthenticatedTemplate } from "@azure/msal-react";
import LandingPage from "./Components/LandingPage/LandingPage";
import RegistrationPage from "./Components/RegistrationPage/RegistrationPage";
import RegistrationCompletedPage from "./Components/RegistrationPage/RegistrationCompletedPage";
import Roles from "./Components/Roles/Roles";
import Registrations from "./Components/Admin/Registrations";
import RegistrationsArchive from "./Components/Admin/RegistrationsArchive";
import Partners from "./Components/Admin/Partners";
import WarrantyDetails from "./Components/Admin/WarrantyDetails";
import NotFound from "./Components/NotFound/NotFound";
import { Route, Routes } from "react-router-dom";
import { appInitialization } from "./initializeApp";

function App() {
  appInitialization();

  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route
        path="/registration/completed"
        element={<RegistrationCompletedPage />}
      />
      <Route
        path="/roles"
        element={
          <AuthenticatedTemplate>
            <Roles />
          </AuthenticatedTemplate>
        }
      />
      <Route
        path="/admin"
        element={
          <AuthenticatedTemplate>
            <LandingPage />
          </AuthenticatedTemplate>
        }
      />
      <Route
        path="/admin/registrations"
        element={
          <AuthenticatedTemplate>
            <Registrations />
          </AuthenticatedTemplate>
        }
      />
      <Route
        path="/admin/archive"
        element={
          <AuthenticatedTemplate>
            <RegistrationsArchive />
          </AuthenticatedTemplate>
        }
      />
      <Route
        path="/admin/warrantyDetails"
        element={
          <AuthenticatedTemplate>
            <WarrantyDetails />
          </AuthenticatedTemplate>
        }
      />
      <Route
        path="/admin/partners"
        element={
          <AuthenticatedTemplate>
            <Partners />
          </AuthenticatedTemplate>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
