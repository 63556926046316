import React from "react";
import { TextField } from "@mui/material";
import TranslationContent from "../../../Translations/TranslationContent";

const FormTextField = ({ labelComponent, ...textFieldProps }) => {
  return (
    <TextField
      label={<TranslationContent contentID={labelComponent} />}
      {...textFieldProps}
      autoComplete="off"
    />
  );
};
export default FormTextField;
